.share-modal-content {
  text-align: center;

  .survey-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    word-break: break-all;
  }

  .qr-code-wrapper {
    margin-bottom: 20px;
  }

  .scan-tip {
    margin-bottom: 8px;
  }

  .url-wrapper {
    font-size: 12px;
    margin-bottom: 20px;
    word-break: break-all;
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}