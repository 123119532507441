@use '@/styles/mixins' as mixins;

.survey-management {
  .survey-management-content {
    padding: 20px 40px;

    @include mixins.mobile {
      padding: 0;
    }
  }
}