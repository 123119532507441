.limited-allowance {
  .limited-alloance-content {
    padding: 20px 40px;

    .card-head-title,
    .ant-card-head-title {
      color: #333;
      font-size: 16px;
      font-weight: 600;
    }

    .tips {
      display: flex;
      align-items: center;
      line-height: 18px;
      margin-top: 20px;

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .status-msg {
      font-weight: 600;
      color: #666;
    }
  }
}