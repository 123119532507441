.booking-page {
  .page-header {
    box-shadow: none;
  }

  .tabs-container {
    .ant-tabs-nav {
      background-color: #fff;
      padding: 0 40px;
    }
  }

  .booking-table-container {
    padding: 0 40px 40px;
  }
}

.slot-booking-tips {
  .list-item {
    font-size: 14px;
    line-height: 30px;
  }

  .list-item-content {
    padding-left: 7px;
  }
}

@media only screen and (max-width: 768px) {
  .ant-modal {
    width: auto !important;
  }

  .booking-page {
    .tabs-container .ant-tabs-nav {
      padding: 0 20px;
    }
  }
}
