.account-settlement-modal {
  .ant-card-head-title {
    font-weight: 600;
  }

  .card-extra {
    .extra-item {
      margin-left: 10px;
    }

    .extra-total {
      color: #666;
      font-weight: 600;
    }
  }
  .arrow-btn-group {
    position: absolute;
    right: 28px;
    top: -50%;
    transform: translateY(50%);
    & span:last-child {
      margin-right: 20px !important;
    }
  }
  .anticon-arrow-left,
  .anticon-arrow-right {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    &:focus,
    &:hover {
      color: #000;;
      text-decoration: none;
    }
  }
  .anticon-arrow-left {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .account-settlement-modal {
    .ant-card-head-wrapper {
      flex-direction: column;
      align-items: flex-end;

      .ant-card-extra {
        margin-left: 0;

        .card-extra {
          font-size: 12px;

          .extra-item {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}