@import "../../../styles/theme/variable.scss";

.contract-report {
  height: 100%;

  .contract-content {
    padding: 24px 40px 70px;
  }

  .search-btn {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .span-placeholder {
      color: #bfbfbf;
    }
  }

  .ant-table-cell-fix-right-first {
    right: -1px !important;
  }

  .advance-btn-col {
    margin-left: auto;

    .advance-btn {
      margin-top: 32px;
    }
  }

  .total-mobile {
    display: none;
  }

  .report-header {
    float: right;

    .total {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .ant-btn {
      margin-left: 10px;
    }
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
    text-align: right;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }

  .lock-icon {
    color: $color-primary;
    height: auto;
    line-height: 1;
  }
}

@media only screen and (max-width: 768px) {
  .contract-report {
    .contract-content {
      padding: 16px 0 70px;
    }

    .total-mobile {
      display: inline-block;
    }

    .total {
      display: none;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }
  }
}
