.invoice-page {
  .invoice-table-container {
    padding: 0 40px 40px;

    .ant-card-head-title {
      font-weight: 600;
    }

    .ellipsis-cell {
      max-width: 50px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .card-extra {
      .extra-item {
        margin-left: 10px;
      }

      .extra-total {
        color: #666;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .invoice-page {
    .invoice-table-container {
      padding: 5px 0;

      .ant-card-head-wrapper {
        flex-direction: column;
        align-items: flex-start;

        .ant-card-extra {
          margin-left: 0;

          .card-extra {
            font-size: 12px;

            .extra-item {
              margin-left: 10px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
