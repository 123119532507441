.mobile-table-operation {
  .ant-btn {
    display: block;
    width: 100%;
    min-width: 60px;
    border: none;
    box-shadow: none;
  }

  .mobile-table-operation-child {
    .ant-btn {
      border-bottom: 1px solid #ddd;
    }
  }
}
