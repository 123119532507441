.credit-balance-overview-wrapper {
  .title {
    color: #252525;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ant-form-item-label>label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .submit-btn {
    margin-top: 30px;
    width: 100%;
  }

  .content {
    .search-result {
      margin-top: 30px;

      .money-info-container {
        .ant-col {
          .result-col-item-container {
            font-size: 14px;
            margin-bottom: 30px;
            color: #252525;

            &:first-child>.result-col-item {
              font-size: 17px;
              font-weight: 600;

              &>.label {
                font-weight: 600;
              }
            }

            .result-col-item {
              display: inline-block;
              min-width: 445px;
            }

            .tips {
              padding-bottom: 7px;
            }

          }
        }
      }
    }

    .tips {
      display: flex;
      align-items: center;
      line-height: 18px;

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .company-name {
      margin-bottom: 10px;
      font-weight: bold;
      
      .label {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      
      .value {
        font-size: 14px;
        color: #1890ff;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .credit-balance-overview-wrapper {
    padding: 0 16px 16px;

    .submit-btn {
      margin-top: 10px;
    }

    .content {
      .search-result {
        .money-info-container {
          .ant-col {
            .result-col-item-container {
              .result-col-item {
                min-width: 210px;
              }
            }
          }
        }
      }
    }
  }
}