#main {
  background-color: #fff;

  .main-footer {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 2px 5px #0000001a;
    height: 54px;
    z-index: 10;
    bottom: 0;

    .footer-container {
      width: 100%;
      height: 100%;
      padding: 0 40px;

      ul li {
        display: inline;
        padding: 0 16px;

        a,
        span {
          color: #666;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }

    /* platform-component */
    .footer-wrapper {
      height: 100%;
      padding: 0 16px;
      display: flex;
      align-items: center;

      .footer-link-group li {
        display: inline;
        padding-right: 12px;
        padding-left: 16px;
      }

      .copyright {
        display: none;
      }
    }
  }

  .main-content {
    padding-left: 200px;

    .main-footer {
      width: calc(100% - 200px);
    }
  }

  .main-content-collapsed {
    padding-left: 80px;

    .main-footer {
      width: calc(100% - 80px);
    }
  }

  .hdp-skeleton {
    width: 100%;

    .skeleton-side-bar {
      width: 200px;
      height: 100vh;
    }

    .skeleton-content {
      width: calc(100% - 200px);

      .skeleton-content-header {
        width: 100%;
      }

      .skeleton-content-paragraph {
        width: 100%;
        padding: 48px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #main {
    .main-content-collapsed {
      padding-left: 60px;

      .main-footer {
        position: relative;
        height: 220px;
        width: 100%;

        .footer-container {
          flex-direction: column;
          padding: 10px 5px;

          .footer-left {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
