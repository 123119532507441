.cg-bread-crumb {
  background-color: #fff;
  position: fixed;
  width: calc(100% - 200px);
  height: 50px;
  z-index: 10;

  .cg-bread-crumb-content {
    height: 100%;
  }

  .trigger {
    font-size: 18px;
    padding: 15px;
    cursor: pointer;
    transition: color 0.3s ease-in;
  }

  .trigger:hover {
    color: #007681;
  }

  .crumb-right {
    padding: 0 40px;

    .option-box {
      cursor: pointer;
      transition: color 0.2s;
      margin-left: 30px;

      .crumb-text {
        padding-right: 6px;
      }
    }

    .option-box:hover {
      color: #007681;
    }

    .notify-icon {
      position: relative;
    }

    .notify-num {
      position: absolute;
      color: #fff;
      background-color: #b55560;
      display: block;
      padding: 0 3px;
      border-radius: 2px;
      top: -8px;
      right: -18px;
      font-size: 12px;
    }

    .logout-action {
      margin-left: 50px;
    }
  }
}

.cg-bread-crumb-collapsedstatus-f {
  width: calc(100% - 80px);
}

@media only screen and (max-width: 768px) {
  .cg-bread-crumb {
    .crumb-right {
      width: 100%;
      padding: 0 10px;

      .option-box {
        margin-left: 10px;
      }
    }
  }

  .cg-bread-crumb-collapsedstatus-f {
    width: calc(100% - 60px);
  }
}
