.cross-border-transfer-notice-page-container {
  max-height: 100vh;
  width: 100%;
  padding: 0 40px 60px;

  .page-content {
    width: 1000px;
    max-height: 100vh;
    overflow-y: auto;
    padding: 80px 30px 240px;

    .page-title {
      font-size: 36px;
      font-weight: 600;
      color: #000;
      letter-spacing: 2px;
      line-height: 45px;
      margin-bottom: 40px;
      text-align: center;
    }

    .rich-text-info {
      b {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        color: #000;
        opacity: 0.9;
        margin: 0;
      }

      p {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      table {
        margin: 0 auto;
        border-collapse: collapse;
        width: 95%
      }

      td {
        text-align: center;
        background-color: white;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
        height: 37px;

      }

      ul,
      li {
        list-style: disc;
      }

      ul {
        padding-left: 14px;
      }
      li {
        padding-left: 7px;
      }

      .td-left {
        width: 160px;
        background-color: #d0cece;
      }

      .td-right {
        width: 435px;
        background-color: white;
      }
    }
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 200px;
    background-color: #f5f5f5;
    padding: 0 40px;

    .footer-content {
      width: 100%;

      .footer-actions {
        width: 1000px;

        .actions-new-policy {
          width: 100%;
          margin-top: 30px;

          .policy-item {
            margin-right: 50px;

            a {
              padding-left: 6px;
            }
          }

          .new-policy-btn {
            margin-top: 20px;

            .btn-item {
              margin-right: 20px;
            }

            .btn-item-reject {
              background-color: #5c5c5c;
              color: #fff;
              border-color: #5c5c5c;
            }
          }
        }

        .action-go-back {
          width: 100%;
          margin-top: 30px;
        }
      }
    }

    .cross-border-transfer-notice-notice {
      color: #007681;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
      padding-left: 6px;
    }
  }
}