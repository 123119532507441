.payment-overview-wrapper {
  .title {
    color: #252525;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ant-form-item-label>label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .submit-btn {
    margin-top: 30px;
    width: 100%;
  }

  .content {
    .search-result {
      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .ant-row.title-container:not(:first-child) {
        margin-top: 48px;
      }

      .result-row {
        // margin-bottom: 48px;

        .ant-col {
          .result-col-item:first-child {
            font-size: 17px;
            font-weight: 600;

            .label {
              font-weight: 600;
            }
          }
        }
      }

      .ant-btn-link {
        padding: 0;
        height: 20px;
        line-height: 20px;
      }
    }

    .tips {
      display: flex;
      align-items: center;
      line-height: 18px;

      &.time-stamp {
        margin: 20px 0 48px;
      }

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .payment-overview-wrapper {
    padding: 0 16px 16px;

    .title {
      margin-bottom: 16px;
    }

    .submit-btn {
      margin-top: 10px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .content {
      .search-result {
        .result-row {
          .ant-col {
            margin-bottom: 48px;
          }
        }
      }
    }
  }
}