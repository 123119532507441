.booking-preview-content {
  .booking-basic-info {
    padding: 10px 0;
    border-bottom: 1px solid #9999994d;

    .detail-list {
      font-size: 14px;
      padding-inline-start: 0;

      .detail-item {
        min-width: 260px;
        padding: 10px 0;

        .title {
          color: #666;
          font-weight: 600;
        }

        .value {
          padding-left: 10px;
          color: #000;
          font-weight: 600;
        }

        .action-text {
          color: #005f86;
          margin-left: 10px;
        }

        .action-text:hover {
          cursor: pointer;
        }
      }
    }
  }

  .booking-datetime-container {
    width: 100%;

    .ul-list {
      padding-left: 0;
    }

    .total-book-num-new {
      margin-left: 20px;
      font-size: 20px;

      .span-book-num {
        font-weight: 600;
        font-size: 20px;
      }
    }

    .hdp-dash-line {
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed #9999994d;
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-modal {
    width: auto !important;
  }

  .booking-datetime-container {
    .date-time-list {
      flex-direction: column;

      .time-list-item {
        width: 100% !important;
      }
    }
  }
}
