.contract-update {
  background-color: #fff;
  padding: 0 40px;
  height: 100%;

  .update-tips {
    font-size: 16px;
    font-weight: 400;
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .title {
    color: #252525;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .contract-update {
    padding: 0 10px 50px;

    .ant-btn {
      width: 100% !important;
      margin-top: 0 !important;
    }
  }
}
