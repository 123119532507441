.setting-tab-container {
  background-color: #fff;
  padding: 0 40px 100px;

  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 8px;
  }

  .ant-form-item-label > label {
    font-size: 13px;
  }

  span.ant-radio + * {
    font-size: 13px;
  }
}

.setting-btn {
  .ant-btn {
    line-height: 1 !important;
    height: auto !important;
    padding: 0 !important;
  }
}

.page-header-cyrus {
  .ant-page-header {
    padding: 16px 0;
    background-color: #fff;
  }

  .ant-page-header-heading-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 768px) {
  .setting-tab-container {
    background-color: #fff;
    padding: 0 10px 10px;
  }

  .page-header-cyrus {
    background-color: #fff;
    padding-left: 16px;
    box-shadow: none;

    .ant-page-header-heading-title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }
}
