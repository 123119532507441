.auto-approve {
  .auto-approve-content {
    padding: 20px 40px;

    .configuration-details {
      .ant-form {
        .warehouses-row {
          &>.ant-col:last-child {
            margin-bottom: 24px;
          }
          &:nth-child(2) {
            &>.ant-col:last-child {
              margin-bottom: 0;
            }
        }
        }
      }
    }
  }
}