.home-page {
  color: #fff;
  font-size: 16px;
  list-style: auto;

  .home-dashboard {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("../../assets/images/login-bg.png") center center fixed;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(3px);
  }

  .home-bg::after {
    top: 0;
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    overflow: hidden;
  }

  .home-content {
    position: absolute;
    margin: 45px;
  }

  .main-page-header {
    .ant-page-header-heading-title {
      font-size: 46px;
      font-weight: 700;
      padding-left: 20px;
      height: 60px;
      line-height: 60px;
      background-color: #fff;
    }
  }

  p {
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  li {
    display: list-item !important;
    margin-left: 40px;
  }

  ol li {
    list-style-type: demical;
    list-style-position: inside;
  }

  ul li {
    list-style-type: disc;
    list-style-position: inside;
  }

  strong {
    font-weight: 600;
  }

  .welcome {
    color: #fff;
    font-weight: 800;
    font-size: 36px;

    span {
      font-size: 46px;
      font-weight: 800;
    }
  }

  .update-title {
    font-size: 18px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .home-page {
    font-size: 14px;
    margin: 0 -5px;

    .home-content {
      margin: 40px 16px;
    }

    .welcome {
      font-size: 16px;

      span {
        font-size: 24px;
      }
    }
  }
}
