@use '@/styles/mixins' as mixins;

// 定义主题色变量
$primary-color: #007681;
$hover-color: rgba(0, 118, 129, 0.8);
$bg-gray: #f5f5f5;
$border-gray: #d9d9d9;

.survey {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: $bg-gray;
  padding: 20px;

  @include mixins.mobile {
    padding: 0;
  }

  >.survey-content {
    max-width: 960px;
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    font-size: 14px;

    @include mixins.mobile {
      padding: 12px;
      border-radius: 0;
      box-shadow: none;
    }

    // 修改基础输入框样式
    %input-base {
      border: none;
      padding: 8px 12px;
      background-color: #f9f9f9;

      &::placeholder {
        color: #bfbfbf;
      }

      &.ant-input-status-error,
      &.ant-input-focused,
      &:hover {
        background-color: #f9f9f9 !important;
      }
    }

    .ant-input {
      @extend %input-base;
    }

    // 通用标题样式
    .section-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    // 评分部分样式
    .rating-section {
      margin: 32px 0;

      @include mixins.mobile {
        margin: 24px 0;
      }

      .section-header {
        margin-bottom: 24px;
        background: #f5f7fa;
        padding: 12px 16px;
        border-radius: 4px;
        border-left: 4px solid $primary-color;

        h3 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: #333;

          @include mixins.mobile {
            font-size: 14px;
          }
        }
      }

      .questions-list {
        .question-item {
          margin-bottom: 24px;

          @include mixins.mobile {
            margin-bottom: 16px;
          }

          .question-title {
            font-weight: bold;
            margin-bottom: 12px;

            &.required::before {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*';
            }
          }
        }
      }
    }

    // 评分选项样式
    .rating-companies {
      .company-rating {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        .company-name {
          min-width: 100px;
          margin-right: 8px;
          color: #ff4d4f;
          font-weight: 500;
          margin-bottom: 8px;

          @include mixins.mobile {
            font-size: 12px;
          }
        }
      }
    }

    .rating-options {
      .ant-radio-group {
        display: flex;
        border-radius: 0;
        overflow: hidden;

        @include mixins.mobile {
          width: 100%;
        }

        .ant-radio-button-wrapper {
          flex: 1;
          text-align: center;
          padding: 0;
          box-shadow: none;
          border: none;
          background: #f5f5f5;
          &-checked {
            color: #fff;
            background: #007681;
            box-shadow: none;
            &:hover {
              color: #fff !important;
            }
          }
          &:not(:last-child) {
            border-right: 1px solid #fff;
          }
          &:not(:first-child)::before {
            display: none;
            background-color: #fff
          }
          &:hover {
            color: initial;
          }
        }
      }

      .rating-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        color: #666;
        font-size: 12px;
        
        @include mixins.mobile {
          font-size: 11px;
        }
      }
    }

    // 角色选择部分样式
    .role-selection-section {

      .section-title {
        margin-bottom: 16px;
      }

      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include mixins.mobile {
          gap: 8px;
        }

        .ant-radio-wrapper {
          margin-right: 0;
          font-size: 14px;
        }
      }
    }

    // 客户类型样式
    .customer-type {
      .ant-radio-group {
        display: flex;
        gap: 32px;

        @include mixins.mobile {
          flex-wrap: wrap;
          gap: 16px;

          .ant-radio-wrapper {
            font-size: 14px;
          }
        }

        .ant-radio-wrapper {
          font-size: 14px;
        }
      }
    }

    // 普通单选框样式
    .ant-radio-wrapper {
      .ant-radio {
        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: $primary-color;
            background-color: #fff;

            &::after {
              background-color: $primary-color;
            }
          }
        }

        .ant-radio-inner {
          &:hover {
            border-color: $primary-color;
          }
        }
      }

      &:hover {
        .ant-radio-inner {
          border-color: $primary-color;
        }
      }
    }

    .ant-radio-focused .ant-radio-inner {
      border-color: $primary-color;
    }

    // 页脚样式
    .survey-footer {
      margin-top: 48px;
      padding-top: 24px;
      border-top: 1px solid #f0f0f0;

      @include mixins.mobile {
        margin-top: 32px;
        padding-top: 20px;

        .submit-container {
          .ant-btn-primary {
            width: 100%;
            height: 36px;
            font-size: 14px;
          }
        }
      }

      .fax-tip {
        text-align: center;
        color: #666;
        margin-bottom: 24px;
        font-size: 14px;

        @include mixins.mobile {
          font-size: 13px;
          padding: 0 16px;
        }
      }

      .submit-container {
        text-align: center;
        margin-top: 20px;

        .ant-btn-primary {
          min-width: 120px;
          height: 40px;
          font-size: 16px;
          border-radius: 4px;
        }
      }
    }

    // 添加 logo 样式
    .survey-logo {
      text-align: center;
      margin-bottom: 24px;

      img {
        width: 140px;
        height: auto;
      }

      @include mixins.mobile {
        margin-bottom: 16px;

        img {
          width: 120px;
        }
      }
    }

    // 添加标题样式
    .survey-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 32px;
      line-height: 1.4;

      @include mixins.mobile {
        font-size: 16px;
        margin-bottom: 24px;
        padding: 0 12px;
      }
    }

    // 确保问题标题也是加粗的
    .question-title {
      font-weight: bold;
    }

    // 确保 section 标题也是加粗的
    .section-title {
      font-weight: bold;
    }

    // 调整问卷说明的间距
    .description {
      margin: 16px 0;
      padding: 16px 24px;
      background: #f8f8f8;
      border-radius: 4px;

      @include mixins.mobile {
        padding: 12px 16px;
      }

      p {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        font-weight: bold;
      }
    }

    // 调整公司输入部分的间距
    .company-input-section {
      .section-title {
        margin-bottom: 12px;
      }
    }

    // 添加表单项错误提示的全局样式
    .ant-form-item {
      position: relative;
      margin-bottom: 24px;

      @include mixins.mobile {
        margin-bottom: 16px;
      }

      .ant-form-item-label {
        >label {
          font-weight: bold; // 加粗 label 文字
        }
      }

      // 错误提示文本容器样式
      .ant-form-item-explain {
        position: absolute;
        font-size: 12px;
        padding-top: 2px;
        bottom: -24px;
      }

      .ant-form-item-control-input {
        min-height: auto;
      }

      &.ant-form-item-has-error {

        .ant-input,
        .ant-input-affix-wrapper,
        .ant-select-selector,
        .ant-picker,
        .ant-input-number,
        .ant-radio-group,
        .ant-checkbox-group {
          border-color: #ff4d4f;
          box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);

        }
      }
    }
  }

  .section-comments,
  .question-comments {
    margin-top: 24px;
    border-radius: 4px;

    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-label {
        >label {
          font-weight: 500;
          color: #666;
          @include mixins.mobile {
            font-size: 12px;
          }
        }
      }
    }

    @include mixins.mobile {
      margin-top: 16px;
    }
  }

  // 修改单选框组的样式
  .boolean-options {
    .ant-radio-group {
      display: flex;
      gap: 32px; // 设置选项之间的间距

      .ant-radio-wrapper {
        font-size: 14px;
        margin-right: 0;

        // 选中状态样式
        &.ant-radio-wrapper-checked {
          .ant-radio-inner {
            border-color: $primary-color;

            &::after {
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }

  .print-modal {
    .ant-modal-confirm {
      .ant-modal-confirm-btns {
        display: none;
      }
    }
  }
}

@media print {
  .survey {
    background: #fff;

    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    print-color-adjust: exact;
    /* 确保在所有浏览器中生效 */

    >.survey-content {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .survey-footer {

      .print-button,
      .submit-button {
        display: none;
      }
    }

    // 隐藏弹窗所有弹窗
    .ant-modal-root {
      display: none;
    }
  }
}

@page {
  size: A4;
  margin: 0;
}