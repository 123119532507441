@import "../../../styles/theme/variable.scss";

.do-merge {
  background-color: #005f86;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  margin-left: 5px;
}

.premade-letter {
  padding: 20px 40px 100px;

  .ellipsis {
    max-width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .ant-table-cell-fix-right-first {
    right: -1px !important;
  }

  .ant-table-cell-fix-left-last {
    left: -1px !important;
  }

  .delete-icon {
    color: $color-primary;
    height: auto;
    line-height: 1;
  }
}

@media only screen and (max-width: 768px) {
  .premade-letter {
    padding: 20px 0;

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }
  }
}
