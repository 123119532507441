.change-contract-modal-table {
  position: relative;

  .change-contract-modal-box {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #ccc, $alpha: 10%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;

    .change-contract-modal-main {
      position: absolute;
      width: 400px;
      height: 140px;
      left: 50%;
      transform: translateX(-50%);
      top: 27%;
      border: 1px solid #ccc;
      text-align: center;
      // background-color: rgba($color: #fff, $alpha: 80%);
      background-color:#fff;

      .title {
        font-weight: 600;
        margin-top: 20px;
      }

      .tips {
        font-size: 12px;
        margin-top: 15px;
      }

      .ant-btn {
        font-size: 12px;
        width: auto;
        height: 28px;
        margin-top: 18px;
      }
    }
  }
}
