.search-filters {
  padding: 20px 40px;

  .ant-card-head-title {
    color: #333;
    font-weight: 500;
  }

  .form-item {
    margin-right: 16px;
    margin-bottom: 10px;

    .ant-form-item-label > label {
      color: #666;
      font-weight: 600;
    }
  }

  .advance-filters {
    margin-top: -24px;

    .advance-filter-actions {
      margin-top: -24px;
    }
  }

  .advance-filters-container .ant-collapse-content-box {
    padding: 16px 0;
  }

  .form-btn-reset {
    background-color: #5c5c5c;
    color: #fff;
  }

  .collapse-active-btn {
    color: #007ed9;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .search-filters {
    padding: 5px 0;

    .advance-filters .advance-filter-actions {
      margin-top: 0;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 575px) {
  .search-filters {
    .ant-form-vertical .ant-form-item {
      flex-direction: initial;
    }
  }
}
