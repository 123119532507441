/*  设为 Flex 布局以后，子元素的float、clear和vertical-align属性将失效  */
.hdp-uf {
  display: flex; /*  新版本语法: Opera 12.1, Firefox 22+  */
}

.hdp-uf-show {
  display: flex !important; /*  新版本语法: Opera 12.1, Firefox 22+  */
}

/*  justify-content 设置或检索弹性盒子元素在主轴（横轴）方向上的对齐方式 */

/* 这里旧版语法有4个参数，而新版语法有5个参数，兼容写法新版语法的 space-around 是不可用的 */

/* box-pack: start | end | center | justify; */

/* 主轴对齐：左对齐（默认） | 右对齐 | 居中对齐 | 左右对齐 */

/* justify-content: flex-start | flex-end | center | space-between | space-around; */

/* 主轴对齐方式：左对齐（默认） | 右对齐 | 居中对齐 | 两端对齐 | 平均分布 */

/* 横向起始位置对齐 */
.hdp-uf-hfs {
  justify-content: flex-start;
}

/* 横向结束位置对齐 */
.hdp-uf-hfe {
  justify-content: flex-end;
}

/* 横向居中 */
.hdp-uf-hc {
  justify-content: center;
}

/* 横向平均分布 */
.hdp-uf-hsb {
  justify-content: space-between;
}

/* 横向平均地分布,两端保留子元素与子元素之间间距大小的一半 */
.hdp-uf-hsa {
  justify-content: space-around;
}

/*  align-items 定义flex子项在flex容器的当前行的侧轴（纵轴）方向上的对齐方式 */

/* 这里的参数除了写法不同，其实是功能是一样的 */

/* box-align: start | end | center | baseline | stretch; */

/* 交叉轴对齐：顶部对齐（默认） | 底部对齐 | 居中对齐 | 文本基线对齐 | 上下对齐并铺满 */

/* align-items: flex-start | flex-end | center | baseline | stretch; */

/* 交叉轴对齐方式：顶部对齐（默认） | 底部对齐 | 居中对齐 | 上下对齐并铺满 | 文本基线对齐 */

/* 纵向起始位置的边界紧靠住该行的纵向起始边界 */
.hdp-uf-vfs {
  align-items: flex-start;
}

/* 纵向起始位置的边界紧靠住该行的纵向结束边界 */
.hdp-uf-vfe {
  align-items: flex-end;
}

/* 纵向居中 */
.hdp-uf-vc {
  align-items: center;
}

/* 如弹性盒子元素的行内轴与侧轴为同一条，则该值与'flex-start'等效。其它情况下，该值将参与基线对齐 */
.hdp-uf-vb {
  align-items: baseline;
}

/* 如果指定侧轴大小的属性值为'auto'，则其值会使项目的边距盒的尺寸尽可能接近所在行的尺寸，但同时会遵照'min/max-width/height'属性的限制 */
.hdp-uf-vs {
  align-items: stretch;
}

/*  flex-direction 该属性通过定义flex容器的主轴方向来决定felx子项在flex容器中的位置。这将决定flex需要如何进行排列 */

/* 子元素的显示方向 */

/* 子元素的显示方向可通过 box-direction + box-orient + flex-direction 实现 */

/* 横向排列 主轴与行内轴方向作为默认的书写模式。即横向从左到右排列（左对齐） */
.hdp-uf-dr {
  flex-direction: row;
}

/* 横向排列 对齐方式与row相反(右到左) */

/* 这里补充说明一点： box 写法的 box-direction 只是改变了子元素的排序，并没有改变对齐方式，需要新增一个 box-pack 来改变对齐方式。 */
.hdp-uf-drr {
  flex-direction: row-reverse;
}

/* 纵向排列 主轴与块轴方向作为默认的书写模式。即纵向从(上往下)排列（顶对齐） */
.hdp-uf-dc {
  flex-direction: column;
}

/* 纵向排列 对齐方式与column相反 (下往上) */
.hdp-uf-dcr {
  flex-direction: column-reverse;
}

/*  flex-wrap 该属性控制flex容器是单行或者多行 */

/* flex容器为单行。该情况下flex子项可能会溢出容器 */
.hdp-uf-wn {
  flex-wrap: nowrap;
}

/* flex容器为多行。该情况下flex子项溢出的部分会被放置到新行，子项内部会发生断行 */
.hdp-uf-ww {
  flex-wrap: wrap;
}

/* 反转 wrap 排列 */
.hdp-uf-wwr {
  flex-wrap: wrap-reverse;
}

/* 是否允许放大缩小 */

/*  上面是允许放大，box语法中 box-flex 如果不是0就表示该子元素允许伸缩，而flex是分开的，
上面 flex-grow 是允许放大（默认不允许），下面的 flex-shrink 是允许缩小（默认允许）。
box-flex 默认值为0，也就是说，在默认的情况下，在两个浏览器中的表现是不一样的  */

/*  这里还有一点，就是新旧语法的算法是不一样的，假设 box-flex 的值不等于0，旧语法中，如果有多余的空间，box-flex 的值越大，说明空白部分的占比越多，反之亦然  */

/*  而新版的语法中，放大的比例是直接按 flex-grow 的值来分配的，flex-grow 的缩放会覆盖 flex-shrink:0  */

/* 是否允许放大 */
.hdp-uf-gw {
  flex-grow: 1;
}

/* 是否允许缩小 */
.hdp-uf-sk {
  flex-shrink: 1;
}

/* 子元素的显示次序 */
.hdp-uf-order {
  order: 1;
}

/* img显示配置 */
.hdp-uf-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.hdp-uf-img1 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hdp-uf-img2 {
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.hdp-uf-img3 {
  background-repeat: repeat-y;
  background-size: 100% auto;
}

.hdp-uf-img4 {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.hdp-uf-img5 {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.hdp-uf-img6 {
  background-repeat: no-repeat;
  background-position: center;
}

.hdp-uf-img7 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
