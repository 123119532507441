.hide-input-border {
  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-search {
    left: 0 !important;
    right: 0 !important;
  }
}

.error-autocomplete-border {
  .ant-select-selector {
    border: 1px solid red !important;
  }
}

.edit-column-autocomplete {
  .ant-select-item {
    padding: 5px !important;
  }
}

.disabled-input-component {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .edit-icon {
    margin-left: 10px;
  }
}
