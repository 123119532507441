.user-center {
  background-color: #fff;
  height: 100%;
  padding: 20px 0 70px 40px;

  .user-info-item {
    padding-bottom: 24px;

    .row-underline-mobile {
      display: none;
    }

    .info-title {
      display: inline-block;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: #191919;
    }

    .msg,
    .create-date {
      font-size: 14px;
      line-height: 1.29;
      color: #666;
    }

    .msg {
      font-weight: bolder;
    }

    .info-detail {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 1px;

      .label {
        color: #666;
      }

      .ant-row {
        width: 100%;
      }
    }

    .tips {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .bind-title {
      font-size: 14px;
      font-weight: bolder;
    }

    .bound-item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      font-size: 12px;

      span {
        color: #005f86;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    img {
      display: block;
    }

    .click-word {
      margin-left: 10px;
      cursor: pointer;
      color: #005f86;
    }
  }

  .change-pwd {
    width: 50%;

    .info {
      font-size: 14px;
      line-height: 1.29;
      color: #000c;
      font-weight: 400;
      padding: 20px 0;

      ul {
        margin-top: 10px;
        padding-left: 19px;
        line-height: 30px;

        li {
          text-indent: 15px;
          list-style: disc;
        }
      }
    }

    .ant-input {
      width: 50%;
    }
  }
}

.qrcode {
  padding: 20px;
  width: 100%;
  text-align: center;

  .qrcode-title {
    color: #cd0d15;
    font-size: 16px;
    font-weight: 600;
  }

  .tip {
    font-size: 16px;
    font-weight: 600;
  }

  #qrcode {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .user-center {
    padding: 20px 16px;

    .row-underline-pc {
      display: none !important;
    }

    .row-underline-mobile {
      display: block !important;
    }

    .change-pwd {
      width: 100%;

      .input-width-mobile {
        width: 100%;
      }

      .submit-btn {
        width: 100%;
      }
    }

    .ant-radio-wrapper {
      margin-top: 10px;
    }

    .ant-divider-horizontal {
      margin: 15px 0;
    }
  }
}
