.apply-form {
  .ant-input {
    font-size: 12px;
    font-weight: 600;
  }

  .price-detail-container {
    padding: 10px 0;

    .detail-list {
      font-size: 14px;
      padding-inline-start: 0;

      .detail-item {
        padding: 10px 80px 10px 0;

        .title {
          color: #666;
          font-weight: bold;
        }

        .value {
          padding-left: 10px;
          font-weight: bold;
        }

        .action-text {
          color: #005f86;
          margin-left: 10px;
        }

        .action-text:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.input-btn {
  .ant-btn {
    margin-top: 25px !important;
  }
}

@media only screen and (max-width: 768px) {
  .input-btn {
    .ant-btn {
      width: 100%;
      margin-top: 0 !important;
    }
  }
}
