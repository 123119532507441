.reverse-booking {
  padding: 20px 40px 0;

  .ant-card-head-title {
    font-weight: 500;
  }

  .action-item {
    padding: 15px 10px;

    .item-title {
      padding-right: 30px;
    }

    .download-text {
      padding-bottom: 6px;
    }

    .download-date-picker-text {
      padding-bottom: 6px;
    }

    .date-range {
      margin-right: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .reverse-booking {
    padding: 5px 0;

    .action-download-plan {
      flex-direction: column;
      align-items: flex-start;

      .download-plan-btn {
        margin-top: 10px;
      }
    }
  }
}
