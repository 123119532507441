.cg-content {
  background: #fff;
  min-height: 50vh;
  margin: 0;
  overflow: initial;
  padding-top: 50px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .cg-content {
    padding: 60px 5px;
  }
}
