.contract-detail {
  padding-bottom: 40px;

  .title {
    margin-bottom: 20px;

    .billing-details {
      float: right;
    }

    span {
      font-weight: 500;
    }

    .light {
      margin-left: 8px;
      color: #666;
      font-size: 14px;
    }
  }

  .row-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 120px;
    color: #191919 !important;
  }

  .ant-row span {
    display: block;
    font-size: 12px;
    color: #666;
  }

  .contract-card {
    margin-top: 24px;

    .title {
      margin-bottom: 16px;
      font-weight: 600;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card {
    padding: 20px 40px;
  }

  .ant-collapse-ghost {
    border: 1px solid #d9d9d9 !important;

    .row-content {
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .base-info-row {
    .ant-col {
      margin: 5px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contract-detail {
    .ant-card {
      padding: 16px;
    }

    .title {
      margin-bottom: 5px;

      .billing-details {
        float: none !important;
        margin-top: 10px;
      }
    }
  }
}
