.home-notice-page {
  .notice-page-container {
    padding: 20px 40px;

    .tips {
      padding-top: 50px;
      color: #333;
      font-size: 26px;
    }

    .ql-editor {
      min-height: 200px;
      max-height: 500px;
      padding: 20px;
    }

    strong {
      font-weight: 600;
    }
  }
}
