.group-detail-content {
  label {
    padding: 5px 0;
  }
}

@media only screen and (max-width: 768px) {
  .ant-modal {
    width: auto !important;
  }
}
