.time-window-config {
  padding: 20px 40px 0;

  .ant-card-head-title {
    color: #333;
    font-weight: 500;
  }

  .form-item {
    margin-right: 16px;
    margin-bottom: 10px;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #666;
    font-weight: 600;
  }

  .window-config-actions {
    margin-top: -24px;
  }

  .form-btn-reset {
    background-color: #5c5c5c;
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .time-window-config {
    padding: 5px 0;

    .window-config-actions {
      margin-top: 0;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 575px) {
  .time-window-config {
    .ant-form-vertical .ant-form-item {
      flex-direction: initial;
    }
  }
}
