.booking-config-page {
  background-color: #fff;
  padding: 24px 40px 100px;

  .tips {
    padding-top: 50px;
    color: #333;
    font-size: 26px;
  }

  .loading-rule {
    .ant-form-item-label > label {
      margin-right: 29px !important;
    }
  }

  .card-extra {
    .extra-item {
      margin-left: 10px;
    }

    .extra-total {
      color: #666;
      font-weight: 500;
    }
  }

  .form-btn-reset {
    background-color: #5c5c5c;
    color: #fff;
    margin-right: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .booking-config-page {
    padding: 5px 0;

    .ant-card-head-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .ant-card-extra {
        margin-left: 0;

        .card-extra {
          font-size: 12px;

          .extra-item {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
