.btn-reset-or-cancel {
  background-color: #5c5c5c;
  color: #fff;
}

.page-header {
  background-color: #fff;
  padding-left: 40px;
  box-shadow: 0 5px 9px -5px rgb(222 219 219 / 50%);

  .ant-page-header-heading-title {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 40px;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px;
}

.base-filter {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
}

/* modal开启导致的滚动条消失 */
.ant-scrolling-effect {
  overflow: initial !important;
  width: 100% !important;
}

/* modal标题加粗 */
.ant-modal-title {
  font-weight: bolder;
  color: #333;
}

/* ant-table设置 */
.ant-table {
  .ant-table-thead {
    font-size: 12px !important;
  }

  .ant-table-tbody > tr > td {
    color: #191919;
    font-weight: 500;
  }
}

.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 8px 13px;
}

/* 分页size选择样式 */
.ant-pagination-options {
  .ant-pagination-options-size-changer {
    .ant-select-dropdown {
      text-align: center;
    }
  }
}

.ant-btn-text[disabled] {
  color: #00000040 !important;
  border-color: transparent !important;
  background: transparent !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

/* menu全局样式 */
.ant-menu-dark .ant-menu-item > span > a {
  color: #f5f5f5;
}

/* 全局提示样式 */
.ant-message {
  top: 90%;
  padding: 0 5% 0 20%;

  .ant-message-notice-content {
    width: 100%;
    min-height: 50px;
    padding: 0;

    .ant-message-custom-content {
      height: 50px;
      line-height: 50px;

      span {
        font-weight: 700;
        font-size: 0.75em;
      }

      span.anticon {
        font-size: 1em;
      }
    }

    .ant-message-error {
      background-color: #b15559;
      color: #fff;
    }

    .ant-message-warning {
      background-color: #e1bb33;
      color: #000;
    }

    .ant-message-success {
      background-color: #333;
      color: #eceff1;
    }
  }
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  margin-left: auto;
}

.ant-checkbox-disabled .ant-checkbox-inner,
.ant-radio-disabled .ant-radio-inner {
  background-color: #eee;
}

@media only screen and (max-width: 768px) {
  .page-header {
    background-color: #fff;
    padding-left: 16px;
    box-shadow: none;
    border-bottom: 2px solid #9999994d;

    .ant-page-header-heading-title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  /* 手机端rangePicker样式 */
  .range-picker-drop-down-mobile {
    width: 70%;

    .ant-picker-date-panel {
      width: 100%;
    }

    .ant-picker-panel-container .ant-picker-panels {
      display: inline-block;
    }

    .ant-picker-date-panel .ant-picker-body {
      padding: 8px 6px;
    }
  }

  .ant-btn {
    font-size: 12px;
    min-width: 60px;
    padding: 2px 8px;
  }
}
