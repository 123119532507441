.custom-upload-modal {
  .modal-close {
    font-size: 22px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1;
  }

  .ant-modal-body {
    padding: 24px 24px 12px;
  }

  .upload-content {
    .upload-input-group {
      display: flex;
      gap: 12px;
      margin-bottom: 16px;

      .file-path-input {
        padding: 4px 12px;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        background: #ffffff;
      }
    }

    .radio-group-wrapper {
      .ant-radio-group {
        display: flex;
        gap: 32px;
      }
    }
  }
} 