.deposit-details-wrapper {

  .ant-card-head-title {
    font-weight: 600;
  }

  .card-extra {
    .extra-item {
      margin-left: 10px;
    }

    .extra-total {
      color: #666;
      font-weight: 600;
    }
  }

  .row-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 120px;
    color: #191919 !important;
  }

  .deposit-details-table-container,
  .account-settlement-modal-container {

    .ant-row span {
      font-size: 12px;
      color: #666;
    }

    .tips {
      display: flex;
      align-items: center;
      line-height: 18px;
      margin-top: 20px;

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }

  .ant-card-head-title {
    white-space: wrap;
  }

  .advance-btn-col {
    margin-left: auto;

    .advance-btn {
      margin-top: 32px;
    }
  }


  .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only {
    text-align: right;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .deposit-details-wrapper {
    .ant-card-head-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .ant-card-extra {
        margin-left: 0;

        .card-extra {
          font-size: 12px;

          .extra-item {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
    }

    .ant-row.ant-row-center {
      justify-content: left;
    }

    .deposit-details-table-container {
      .tips {
        padding-right: 16px 24px;

        .anticon {
          color: #ccc;
          margin-right: 5px;
        }

        .label {
          color: #666;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 18px;
        }
      }
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }

    .ant-card-head {
      padding: 0 16px;
    }
  
    .ant-card-body {
      padding: 16px;
    }
  }
}