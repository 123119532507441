.settlement-list {
  padding: 24px 40px 70px;

  .search-btn {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .span-placeholder {
      color: #bfbfbf;
    }
  }

  .trigger-btn {
    .ant-btn {
      line-height: 1 !important;
      height: auto !important;
      padding: 0 !important;
      font-size: 8px;
    }
  }

  .report-header {
    float: right;

    .total {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .ant-btn {
      margin-left: 10px;
    }
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 8px;
  }
}
