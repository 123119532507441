.time-window-page {
  padding-bottom: 80px;

  .tips {
    padding-top: 50px;
    color: #333;
    font-size: 26px;
  }

  .time-window-table-container {
    padding: 20px 40px 0;

    .detail-form-container {
      .detail-container {
        padding: 10px 0;

        .detail-list {
          font-size: 14px;
          padding-inline-start: 0;

          .detail-item {
            padding: 10px 50px 10px 0;

            .title {
              color: #666;
              font-weight: bold;
            }

            .value {
              padding-left: 10px;
              font-weight: bold;
            }

            .action-text {
              color: #005f86;
              margin-left: 10px;
            }

            .action-text:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .header-product-info {
      padding: 0 5px;
    }

    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: 2px 0;
      text-align: center;
      border-right: none;
    }

    .window-table-item {
      min-width: 25%;
      height: 36px;
      background-color: #fff;
      color: #fff;

      &.margin-r {
        margin-right: 1%;
        min-width: 24%;
      }
    }

    .window-table-item-on {
      background-color: #007681;
    }

    .window-table-item-transport {
      background-color: #56a9c5;
    }

    .window-table-item-ban {
      background-color: #ccc;
    }

    .ant-table-thead > tr {
      background-color: #007681;

      th {
        background-color: #007681;
        color: #fff;
      }
    }

    .ant-card-head-title {
      font-weight: 500;
    }

    .ellipsis-cell {
      max-width: 50px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .active-field {
      cursor: pointer;
      outline: none;
      color: #07c;
    }

    .action-item {
      cursor: pointer;
      width: 50px;
    }

    .action-item-disabled {
      cursor: not-allowed;
    }

    .card-extra {
      .extra-item {
        margin-left: 10px;

        .table-clear-selected {
          background-color: #5c5c5c;
          color: #fff;
          margin-right: 20px;
        }
      }

      .extra-total {
        color: #666;
        font-weight: 500;
      }

      .preview-item {
        padding-top: 15px;

        .table-card-title {
          color: #252525;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 20px;
          padding-right: 15px;
        }

        .table-card-tips {
          color: #000;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 20px;
        }
      }

      .preview-item-action {
        cursor: pointer;
      }
    }
  }

  .table-item-color-info {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .color-info-item {
      margin-left: 10px;
      display: flex;

      .color {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .color-1 {
        background-color: #007681;
      }

      .color-2 {
        background-color: #56a9c5;
      }

      .color-3 {
        background-color: #ccc;
      }
    }
  }
}

.preview-all-info {
  .datetime-item {
    width: 120px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
    color: #666;
    background: #fff;
    font-size: 14px;
    box-shadow: 0 2px 0 #0000000b;
    user-select: none;
  }

  .datetime-item-on {
    color: #fff;
    background: #007ed9;
    border-color: #007ed9;
  }

  label {
    margin-left: 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .time-window-page {
    .time-window-table-container {
      padding: 5px 0;

      .ant-card-head-wrapper {
        flex-direction: column;
        align-items: flex-start;

        .ant-card-head-title {
          .preview-item .table-card-tips {
            font-size: 12px;
          }
        }

        .ant-card-extra {
          margin-left: 0;

          .card-extra {
            font-size: 12px;

            .extra-item {
              margin-left: 5px;
            }
          }
        }
      }

      .detail-form-container .detail-container .detail-list {
        font-size: 12px;
      }
    }
  }
}
