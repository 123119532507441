.questionnaire-survey {
  .preview-mode-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background: #007681;
    color: #fff;
    text-align: center;
    z-index: 1000;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .survey-expired {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background: #f5f5f5;

    .expired-logo {
      width: 140px;
      height: auto;
      margin-bottom: 40px;
    }

    .expired-message {
      text-align: center;
      background: #fff;
      padding: 40px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

      h2 {
        color: #333;
        font-size: 24px;
        margin-bottom: 16px;
      }

      p {
        color: #666;
        font-size: 16px;
        margin: 0;
      }
    }
  }
}