.record-tab-container {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    padding-left: 40px !important;
    background-color: #fff !important;
    margin-bottom: 0;
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .advance-btn-col {
    margin-left: auto;

    .advance-btn {
      margin-top: 30px;
    }
  }
}

.record-btn {
  .ant-btn {
    line-height: 1 !important;
    height: auto !important;
    padding: 0 !important;
  }
}

.apply-popover {
  .ant-popover-message {
    padding: 0;
  }

  .ant-popover-message-title {
    padding-left: 0;
    text-align: center;

    .table-option {
      .ant-btn {
        display: block;
      }

      span:nth-child(2),
      span:nth-child(3) {
        display: block;
        border-top: 1px solid #ddd;
      }
    }
  }

  .ant-popover-buttons {
    display: none;
  }
}

.edit-form {
  .check-label {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .record-tab-container {
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      padding: 0 16px !important;
      background-color: #fff !important;
    }

    .ant-tabs-tabpane {
      padding: 0;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }
  }
}
