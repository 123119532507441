.form-title {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.align-right {
  text-align: right;
}

.table-title {
  color: #252525;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-left: 0;
  font-weight: 500;
}

.upload-notice {
  margin: 10px 0 0 5px;
  font-size: 12px;
}

.total-item {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  float: right;
  line-height: 48px;
}
