.apply-form-input {
  .form-item {
    margin-right: 16px;
    margin-bottom: 10px;
  }

  .form-btn-reset {
    background-color: #5c5c5c;
    color: #fff;
  }

  .tips {
    display: flex;
    align-items: center;
    line-height: 18px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.warn {
      color: #CD0D15;
    }

    &.info {
      color: #638C1C;
    }

    .anticon {
      margin-right: 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .label {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .extra-item {
    .ant-form-item-extra {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}