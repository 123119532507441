.chop {
  .page-header {
    box-shadow: none;
  }

  .tabs-container {
    .ant-tabs-nav {
      background-color: #fff;
      padding: 0 40px;
    }

    .ant-tabs-tabpane {
      padding: 20px 40px;
    }

    .card-extra {
      .extra-item {
        margin-left: 10px;
      }

      .extra-total {
        color: #666;
        font-weight: 600;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .advance-btn-col {
      margin-left: auto;

      .advance-btn {
        margin-top: 32px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .chop {
    .tabs-container {
      .ant-tabs-nav {
        padding: 0 20px;
      }

      .ant-tabs-tabpane {
        padding: 0;
      }

      .ant-card-extra {
        margin-left: 0;

        .card-extra {
          font-size: 12px;

          .extra-item {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }
  }
}