.production-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.generate-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  color: #191919;
}

.production-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.production-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #000;
}

.production-bottom-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  text-align: center;
  color: #999;

  & span {
    margin-left: 10px;
  }
}

.production-bottom-left-label {
  background-color: #eceff1;
  font-weight: 400;
  text-align: center;
  color: #5c5c5c;
  padding: 2px 6px;
  border-radius: 5px;
}

.production-bottom-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  text-align: center;
  color: #999;
}

.no-production-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #999;
}
