.account-statement-wrapper {
  .account-statement-container {
    padding: 24px 40px 70px;
    margin-top: 16px;
  }

  .title {
    color: #252525;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ant-form-item-label>label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .submit-btn {
    margin-top: 30px;
    width: 100%;
  }

  .content {
    .search-result {
      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .result-row {
        margin-bottom: 48px;

        .ant-col {
          .result-col-item:first-child {
            font-size: 17px;
            font-weight: 600;

            .label {
              font-weight: 600;
            }
          }
        }
      }

      .ant-btn-link {
        padding: 0;
        height: 20px;
        line-height: 20px;
      }

      .download-group {
        margin-bottom: 20px;
      }

      .download-btn {
        margin-right: 20px;
      }
    }

    .tips {
      display: flex;
      line-height: 18px;

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        padding-top: 2px;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .tips_time {
      margin-top: 48px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .account-statement-wrapper {
    .account-statement-container {
      padding: 0 16px 16px;
    }

    .title {
      margin-bottom: 16px;
    }

    .submit-btn {
      margin-top: 10px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }
  }
}