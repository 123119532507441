.error {
  min-height: calc(100vh - 120px);

  .error-code {
    font-size: 66px;
  }

  .error-tips {
    font-size: 26px;

    .action-btn {
      margin-top: 30px;
    }
  }
}
