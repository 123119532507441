.cg-sider {
  position: fixed;
  height: 100vh;
  overflow: auto;
  left: 0;

  .cargill-logo-container {
    padding: 30px 0;

    .site-logo-white-img {
      width: 120px;
      height: auto;
    }

    .logo-content:hover {
      cursor: pointer;
    }
  }

  .user-role {
    height: auto;
    display: flex;
    color: #f5f5f5;

    .role-name {
      display: block;
      font-size: 14px;
      line-height: 18px;
      white-space: pre-wrap;
    }

    .role {
      display: block;
      font-size: 12px;
      line-height: 16px;
      margin-top: 5px;
      opacity: 0.6;
      white-space: pre-wrap;
    }
  }

  .sider-menu {
    border-right: 0;

    .ant-menu-item:first-child {
      height: auto;
    }
  }

  .user-role-menu-item {
    height: auto;
    padding: 10px 0 10px 24px;
    color: #f5f5f5;

    .ant-menu-item-icon {
      align-self: baseline;
    }
  }

  .ant-menu.ant-menu-root.ant-menu-inline > .ant-menu-item:first-child {
    padding: 10px 0 10px 24px;
  }

  .manual-link,
  .manual-link-text {
    color: #f5f5f5;
  }

  .ant-menu-dark .ant-menu-item > span > a {
    color: #f5f5f5;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #f5f5f5;
  }

  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
  }
}

.ant-layout-sider-collapsed.cg-sider {
  .cargill-logo-container {
    padding: 30px 0;

    .site-logo-white-img {
      width: 60px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-sider {
    min-width: 60px !important;
    width: 60px !important;
  }

  .ant-layout-sider-collapsed.cg-sider {
    .cargill-logo-container {
      padding: 30px 0 20px;

      .site-logo-white-img {
        width: 35px;
      }
    }
  }
}
