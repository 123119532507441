.btn-cancel {
  background-color: #5c5c5c;
  color: #fff;
}

.download-time-window-content {
  label {
    padding: 5px 0;
  }

  label.required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
}

@media only screen and (max-width: 768px) {
  .download-time-window-btn {
    margin-left: 20px;
  }
}
