.price-point-comfirmation {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    padding-left: 40px !important;
    background-color: #fff !important;
  }

  .ant-tabs-tabpane {
    padding: 20px 40px;
  }

  .report-header {
    float: right;

    .total {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .ant-btn {
      margin-left: 10px;
    }
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 8px;
  }

  .ant-form-item {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .price-point-comfirmation {
    padding: 0;

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      padding-left: 10px !important;
      background-color: #fff !important;
    }

    .ant-tabs-tabpane {
      padding: 0;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn {
      margin-top: 10px;

      .ant-btn {
        margin: 0 !important;
        width: 50%;
      }
    }
  }
}
