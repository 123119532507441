.payment-details {
  background-color: #fff;
  // height: 100%;

  .ant-tabs>.ant-tabs-nav,
  .ant-tabs>div>.ant-tabs-nav {
    padding: 0 40px !important;
    background-color: #fff !important;
  }

  .ant-tabs-tabpane {
    padding: 20px 40px;
  }
}

@media only screen and (max-width: 768px) {
  .payment-details {

    .ant-tabs>.ant-tabs-nav,
    .ant-tabs>div>.ant-tabs-nav {
      padding: 0 16px !important;
      background-color: #fff !important;
    }

    .ant-tabs-tabpane {
      padding: 0;
    }
  }
}