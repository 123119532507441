.scheduled-list-container {
  .scheduled-list-table-container {
    padding: 0 40px 40px;

    .ant-card-head-title {
      font-weight: 500;
    }

    .ellipsis-cell {
      max-width: 50px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .active-field {
      cursor: pointer;
      outline: none;
      color: #07c;
    }

    .action-item {
      cursor: pointer;
      width: 50px;
    }

    .action-item-disabled {
      cursor: not-allowed;
    }

    .card-extra {
      .extra-item {
        margin-left: 10px;

        .table-clear-selected {
          background-color: #5c5c5c;
          color: #fff;
          margin-right: 20px;
        }
      }

      .extra-total {
        color: #666;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .scheduled-list-container {
    .scheduled-list-table-container {
      padding: 5px 0;
    }
  }
}
