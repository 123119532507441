@import "../../../styles/theme/variable.scss";

.delivery-list {
  .delivery-list-content {
    padding: 20px 40px 70px;
  }

  .ant-form-item-label>label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .advance-btn-col {
    margin-left: auto;

    .advance-btn {
      margin-top: 32px;
    }
  }

  .report-header {
    float: right;

    .total {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .ant-btn {
      margin-left: 10px;
    }
  }

  .fixed-left-col {
    right: -1px !important;
  }

  .fixed-left-col-sec {
    right: 65px !important;
  }

  td.action-item-col {
    background-color: #f2f2f2;
  }

  .ellipsis {
    max-width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .ant-table-column-sorter {
    margin-left: 5px;
  }

  .table-edit-btn,
  .table-retract-btn {
    cursor: pointer;
  }

  .delivery-list-operation-btn, 
  .delivery-list-operation-btn .ant-btn {
    width: 50px;
    height: auto;
    font-size: 16px;
    padding: 0;
    color: $color-primary;
    line-height: 1;
  }

  .no-label-form-item {
    margin-top: 30px;
  }

  .ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only {
    text-align: right;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }

  .ant-table-cell-fix-right.ant-table-cell-row-hover {
    background-color: #e6e6e6 !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #aec2c0 !important;
  }

  .row-space-arround {
    justify-content: space-between;

    .ant-col {
      flex: 1;
    }
  }
  .download-coa {
    display: inline;
  }
}

@media only screen and (max-width: 768px) {
  .delivery-list {
    .delivery-list-content {
      padding: 16px 0 70px;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .report-header {
      span {
        margin-right: 10px;
      }
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }

    .delivery-list-operation-btn {
      width: 25px;

      .table-retract-btn {
        font-size: 14px;
      }

      .ant-btn-icon-only > * {
        font-size: 12px;
      }
    }

    .table-edit-btn {
      font-size: 12px;
    }

    .no-label-form-item {
      margin-top: 0;
    }

    .ant-table-cell-fix-right.ant-table-cell-row-hover {
      background-color: #fafafa !important;
    }
  }
}