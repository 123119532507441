.login-page-content {
  min-height: 100vh;
  width: 100%;
  background: url("../../assets/images/login-bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;

  .bg-shadow {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #0003;
  }

  .footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    content: "";
    z-index: 11;
    background: #252525;
    opacity: 0.9;
    width: 100%;
    height: 40px;

    .footer-content {
      width: 100%;
      height: 100%;
      padding: 0 40px;

      ul li {
        display: inline;
        padding: 0 16px;

        span {
          color: #fff;
          font-size: 12px;
        }

        a {
          color: #fff;
          font-size: 12px;
          text-decoration: none;
        }

        a:hover {
          cursor: pointer;
          color: #005f86;
        }
      }
    }
  }

  .login-container {
    z-index: 11;

    .login-form {
      min-width: 300px;

      img.auth-org-logo {
        height: 60px;
        width: auto;
      }

      #okta-sign-in {
        margin: 10px auto 0;
        border-radius: 3px 3px 0 0;
        box-shadow: none;
        border-bottom: none;
      }

      #okta-sign-in.no-beacon .auth-header {
        padding-bottom: 0;
      }

      #okta-sign-in .okta-form-title {
        display: block;
        font-size: 16px;
      }

      #okta-sign-in .help-links li {
        text-align: center;
      }

      #okta-sign-in .link.help:focus {
        box-shadow: none;
      }

      #okta-sign-in .help-links li:nth-child(2) {
        display: none;
      }

      #okta-sign-in .help-links li:nth-child(3) {
        display: none;
      }

      #okta-sign-in .link.js-forgot-password {
        margin-right: 9px;
      }

      #okta-sign-in .auth-footer {
        margin-bottom: 0;
      }
    }

    .login-form-button {
      width: 100%;
    }

    .widget-footer {
      text-align: center;

      .footer-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-top: 1px solid #ddd;
        border-radius: 0 0 3px 3px;
        font-size: 14px;
        height: 50px;
        text-decoration: none;
        background: #f4f4f4;
        color: #000;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-page-content {
    flex-direction: column;

    .footer-container {
      position: relative;
      height: 180px;
      background: transparent;

      .footer-content {
        flex-direction: column;

        .footer-left {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}