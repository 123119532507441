.row-dragging {
    border: 1px solid #ccc;
    z-index: 1000;
}

.row-dragging td {
    padding: 8px 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}