.message-search-filters {
  padding: 0;

  .form-title {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .ant-card-head-title {
    color: #333;
    font-weight: 500;
  }

  .form-item {
    margin-right: 16px;
    margin-bottom: 10px;

    .ant-form-item-label > label {
      color: #666;
      font-weight: 600;
    }

    .date-str-list {
      padding: 0;

      .list-item {
        width: 60px;
        height: 32px;
        padding: 0 5px;
        border: 1px solid #ccc;
        color: #666;
        margin-right: 5px;
        font-size: 12px;
        border-radius: 2px;
      }

      .list-item-on {
        color: #007681;
        font-weight: bolder;
        border: 2px solid #007681;
      }

      .list-item-focus {
        color: #fff;
        background-color: #007681;
        border-color: #007681;
      }

      .list-item:hover {
        cursor: pointer;
        border-color: #007681;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .reservation-filters {
    padding: 5px 0;

    .ant-form-vertical .ant-form-item {
      .date-str-list {
        .list-item {
          margin: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .reservation-filters {
    .ant-form-vertical .ant-form-item {
      flex-direction: initial;
    }
  }
}
