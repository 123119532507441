.do-booking-content {
  .booking-basic-info {
    padding: 10px 0;
    border-bottom: 1px solid #9999994d;

    .detail-list {
      font-size: 12px;
      padding-inline-start: 0;

      .detail-item {
        min-width: 260px;
        padding: 10px 0;

        .title {
          color: #666;
          font-weight: 600;
        }

        .value {
          padding-left: 10px;
          color: #000;
          font-weight: 600;
        }

        .action-text {
          color: #005f86;
          margin-left: 10px;
        }

        .action-text:hover {
          cursor: pointer;
        }
      }
    }
  }

  .booking-datetime-container {
    width: 100%;
    margin: 20px 0;

    .ul-list {
      padding-left: 0;
    }

    .hdp-dash-line {
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed #9999994d;
      margin: 20px 0;
    }

    .datetime-item {
      width: 150px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 6px 12px;
      margin-right: 5px;
      margin-top: 5px;
      cursor: pointer;
      color: #666;
      background: #fff;
      font-size: 12px;
      box-shadow: 0 2px 0 #0000000b;
      user-select: none;
    }

    .datetime-item-on {
      color: #fff;
      background: #007ed9;
      border-color: #007ed9;
    }

    .datetime-item-disabled {
      cursor: not-allowed;
      color: #00000040;
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }

    .datetime-item-transport {
      color: #000;
      background: #dbebf8;
      border-color: #dbebf8;
    }

    .datetime-item:not(.datetime-item-on):not(.datetime-item-disabled):hover {
      color: #007ed9;
      border: 1px solid #007ed9;
    }

    .datetime-item.time {
      width: 80px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-modal {
    width: auto !important;
  }

  .booking-datetime-container {
    .date-time-list {
      flex-direction: column;

      .time-list-item {
        width: 100% !important;
      }
    }
  }
}
