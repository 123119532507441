.deposit-overview-wrapper {
  padding: 0 0 20px;

  .ant-card-head-title {
    font-weight: 600;
  }

  .search-filter {
    padding: 0;

    .ant-card-head-title {
      color: #333;
      font-weight: 600;
    }

    .reset-btn {
      background-color: #5c5c5c;
      color: #fff;
    }

    .collapse-active-btn {
      color: #007ed9;
      cursor: pointer;
    }
  }

  .content {
    padding: 0 13px;

    .result-col-item {
      color: #666;
      font-size: 13px;
      margin-right: 10px;

      &:first-child>span {
        font-weight: 600;
      }
    }

    .tips {
      display: flex;
      align-items: center;
      line-height: 18px;
      &.time-stamp-notice {
        margin-top: 20px;
      }

      .anticon {
        color: #ccc;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }

      .label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .deposit-overview-wrapper {
    // padding: 5px 0;

    // .ant-card-head {
    //   padding: 0 16px;
    // }

    // .ant-card-body {
    //   padding: 16px;
    // }

    .content {
      padding: 0;
    }

    .advance-filters .advance-filter-actions {
      margin-top: 0;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 575px) {
  .deposit-overview-wrapper {
    .ant-form-vertical .ant-form-item {
      flex-direction: initial;
    }
  }
}