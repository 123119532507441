.production-record {
  padding: 24px 40px 70px;

  .all-buyer {
    line-height: 32px;
    margin-right: 16px;
  }

  .total-item {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    float: right;
    line-height: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .production-record {
    padding: 10px 0;

    .ant-btn {
      margin: 0 !important;
      width: 100%;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 100%;
        }
      }
    }
  }
}
