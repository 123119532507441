.field-setting {
  display: inline;
}

.field-setting-modal {
  .ant-transfer {
    justify-content: center;
  }

  .ant-transfer-operation .ant-btn:first-child {
    margin-bottom: 20px;
  }

  .ant-transfer-list-header {
    display: none;
  }

  .ant-table-body {
    padding: 10px 20px;
    min-height: 400px;
  }

  .ant-table-thead > tr > th {
    text-align: right;
  }

  .ant-transfer-operation {
    margin: 0 50px;
  }

  tr.drop-over-downward td {
    border-bottom: 2px dashed #007681;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed #007681;
  }

  .tips {
    float: left;

    span {
      display: inline-block;
      width: 40px;
      height: 20px;
      background-color: #007681;
      margin-right: 10px;
    }
  }

  .ant-empty-normal {
    height: 320px;
  }

  .fixed-tr-filled {
    background-color: #007681 !important;
    cursor: default !important;

    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      display: none;
    }

    .ant-table-cell {
      color: #fff;
    }
  }

  .ant-table-row {
    background-color: #e1e1e4;
    border: 1px solid #cfcfd1;
    padding: 0 5px;
    font-size: 12px;
    line-height: 1;

    .ant-table-cell:first-child {
      border: 5px solid white;
      border-right: none;
    }

    .ant-table-cell:last-child {
      border: 5px solid white;
      border-left: none;
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #e1e1e4;
  }

  .ant-table-tbody > .fixed-tr-filled:hover > td {
    background-color: #007681;
  }

  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #fff;
  }

  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
    padding: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .field-setting-modal {
    .ant-transfer-list-body {
      .ant-table {
        font-size: 12px;
      }
    }

    .ant-table-body {
      padding: 10px 3px;
    }

    .ant-modal-body {
      padding: 10px;
    }

    .ant-transfer-operation {
      margin: 0 10px;
    }

    .ant-modal-footer {
      font-size: 12px;
      padding: 10px;

      .tips {
        display: flex !important;
        float: none;
        margin-bottom: 10px;

        span {
          width: 20px;
          height: 12px;
        }
      }
    }
  }
}
