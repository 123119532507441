.edit-text-error-info {
  color: #ff4d4f;
}

.delivery-letter-list-edit-text {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.edit-form {
  .ant-form-item {
    .check-label {
      color: #666;
    }
  }
}
