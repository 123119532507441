.payable-details-wrapper {

  .ant-card-head-title {
    font-weight: 600;
  }

  .card-extra {
    .extra-item {
      margin-left: 10px;
    }

    .extra-total {
      color: #666;
      font-weight: 600;
    }
  }

  .advance-btn-col {
    margin-left: auto;
  }

  .money-item {
    font-size: 12px;

    .label {
      color: #666;
    }
  }

  .tips {
    display: flex;
    align-items: center;
    line-height: 18px;
    margin-top: 20px;

    .anticon {
      color: #ccc;
      margin-right: 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .label {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .payable-details-wrapper {
    .ant-card-head-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .ant-card-extra {
        margin-left: 0;

        .card-extra {
          font-size: 12px;

          .extra-item {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}