.dispatch-list {
  padding: 24px 40px 70px;

  .search-btn {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .span-placeholder {
      color: #bfbfbf;
    }
  }

  .report-header {
    float: right;

    .total {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .ant-btn {
      margin-left: 10px;
    }
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 8px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
    text-align: right;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .dispatch-list {
    padding: 10px 0 !important;

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      padding-left: 10px !important;
      background-color: #fff !important;
    }

    .ant-tabs-tabpane {
      padding: 0;
    }

    .base-filter {
      .ant-btn {
        margin: 0 !important;
        width: 100%;
      }
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn {
      margin-top: -10px !important;

      .ant-btn {
        margin: 0 !important;
        width: 50% !important;
      }
    }
  }
}
