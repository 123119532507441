.flex-row-class {
  .ant-col {
    flex: 2;
  }

  .flex-row-class-btn {
    flex: 1;
  }
}

@media only screen and (max-width: 768px) {
  .flex-row-class {
    .ant-col {
      flex: 1 1 100%;
    }
  }
}
