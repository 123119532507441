@import "../../../styles/theme/variable.scss";

.consign-apply {
  .consign-apply-content {
    padding: 20px 40px 70px;
  }

  .apply-form-input {
    .ant-select-selection-placeholder {
      font-size: 12px;
    }
  }

  .ant-table-cell-fix-right-first {
    right: -1px !important;
  }

  .ellipsis {
    max-width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .report-info {
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
  }

  .ant-form-item-label > label {
    color: #666;
    letter-spacing: 1px;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .advance-btn-col {
    margin-left: auto;

    .advance-btn {
      margin-top: 32px;
    }
  }

  .collapse-padding {
    margin-top: -25px;

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }

  .do-id {
    cursor: pointer;
    border-bottom: 1px solid #007681;
    color: #007681;
  }

  .do-merge {
    background-color: #007681;
    color: #fff;
    padding: 2px 4px;
    border-radius: 5px;
    margin-left: 5px;
  }

  .total {
    color: #666;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    margin-right: 10px;
  }

  .ant-input-prefix {
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
  }

  .base-info-row {
    .ant-input {
      font-weight: 600;
      font-size: 13px;
      max-width: 200px !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    .base-info-col {
      min-width: 25%;
    }
  }

  .apply-form-select-label {
    flex-direction: row;
    align-items: center;

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item-label > label {
      font-size: 13px;
    }

    div:last-child {
      margin-left: 2px;
      max-width: 200px;
    }
  }

  .input-prefix-important {
    .ant-input-prefix {
      font-size: 17px;
    }

    .ant-input {
      font-size: 17px;
    }
  }

  .created-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
    text-align: right;
  }

  .flex-row-class {
    .ant-col {
      flex: 2;
    }

    .flex-row-class-btn {
      flex: 1;
    }
  }

  .report-filter {
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding-right: 16px;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 0;
    }
  }

  .delete-icon {
    color: $color-primary;
    height: auto;
    line-height: 1;
  }
}

@media only screen and (max-width: 768px) {
  .consign-apply {
    .consign-apply-content {
      padding: 0;
      padding-top: 16px;
    }

    .collapse-padding {
      margin-top: -10px;
    }

    .ant-card {
      margin-bottom: 20px !important;
    }

    .ant-card-head {
      padding: 0 16px;
    }

    .ant-card-body {
      padding: 10px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .apply-form-input,
    .created-table {
      padding: 16px;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .base-submit-btn {
      width: 100%;
    }

    .advance-btn-col {
      flex: auto !important;

      .advance-btn {
        margin-top: 10px;

        .ant-btn {
          margin: 0 !important;
          width: 50%;
        }
      }
    }

    .flex-row-class {
      .ant-col {
        flex: 1 1 100%;
      }
    }
  }
}
