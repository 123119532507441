@font-face {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  src: url("./cargill-design/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff"),
    url("./cargill-design/fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div,
span,
iframe,
map,
font,
img,
a img,
samp,
strong,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
b,
p,
blockquote,
a,
address,
code,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
tr,
td,
th {
  padding: 0;
  margin: 0;
  border: 0;
  font-weight: normal;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
}

html,
body {
  height: 100%;
}

body {
  background: #fff;
}

p {
  margin: 0 !important;
}

ul,
li {
  list-style: none;
}

input {
  background: none;
  outline: none;
  border: 0;
  width: 100%;
  padding: 0.75rem 0.35rem;
  border-radius: 0.15rem;
}

input::placeholder {
  font-size: 12px;
  color: #ccc;
}

/* ie11显示显示加载不同的样式 */

/* IE10-specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input::placeholder {
    font-size: 14px;
  }

  input::-ms-clear {
    display: none;
  }

  input::-ms-reveal {
    display: none;
  }
}
