.datetime-item {
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  color: #666;
  background: #fff;
  font-size: 12px;
  box-shadow: 0 2px 0 #0000000b;
  user-select: none;
}

.datetime-item-on {
  color: #fff;
  background: #007ed9;
  border-color: #007ed9;
}

.datetime-item-disabled {
  cursor: not-allowed;
  color: #00000040;
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.datetime-item-transport {
  color: #000;
  background: #dbebf8;
  border-color: #dbebf8;
}

.datetime-item:not(.datetime-item-on):not(.datetime-item-disabled):hover {
  color: #007ed9;
  border: 1px solid #007ed9;
}

.datetime-item.time {
  width: 110px;
}
